import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#ffffff',
      neutralLight3: '#EFEFEF',
      neutralLight2: '#D8D8D8',
      neutralLight1: '#B9B9B9',
      neutralDark4: '#676768',
      neutralDark1: '#000000',
      primaryLight1: '#D1C7BD',
      primaryDark1: '#A76B73',
      dangerLight1: '#EB5757',
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Shippori Mincho', serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
